import React from "react";

import { Link } from "gatsby";

const footer = () => {
  return (
    <footer>
      <p>Nuan Ho © 2021 - Site by NakedLunch</p>
    </footer>
  );
};

export default footer;
